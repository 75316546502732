<template>
  <v-container fill-height fluid>
    <v-layout justify-center align-center>
      <v-flex xs12>
        <material-card color="#9e8f7d" title="Realizar Venda">
          <v-form>
            <v-container py-0>
              <v-layout wrap>
                <v-flex xs12 md7>
                  <v-text-field
                    class="purple-input"
                    label="Código do produto"
                    v-model="codigodoProdutonaBusca"
                  />
                </v-flex>
                <v-flex>
                  <v-btn color="#9e8f7d" icon v-on:click="getProdutoByCode()">
                    <v-icon>mdi-plus</v-icon>
                  </v-btn>
                </v-flex>

                <v-progress-linear
                  v-if="loading"
                  indeterminate
                  color="#e6c597"
                ></v-progress-linear>
                <v-flex xs12 md10>
                  <v-data-table
                    :headers="headers"
                    :items="list_produtos"
                    hide-actions
                  >
                    <template slot="headerCell" slot-scope="{ header }">
                      <span
                        class="subheading font-weight-light text--darken-3"
                        v-text="header.text"
                      />
                    </template>
                    <template slot="items" slot-scope="{ item }">
                      <td>{{ item.nome }}</td>
                      <td>
                        R$
                        {{
                          parseFloat(item.preco)
                            .toFixed(2)
                            .toString()
                            .replace(".", ",")
                        }}
                      </td>
                      <td>{{ item.codigodebarras }}</td>
                      <td>
                        <v-text-field
                          style="width: 100px"
                          label="Valor (R$)"
                          v-model="item.precoPago"
                          @change="calculateTotal()"
                        />
                      </td>
                      <td>
                        <v-icon @click="remover(item)" size="16"
                          >mdi-close-circle</v-icon
                        >
                      </td>
                    </template>
                  </v-data-table>
                </v-flex>
                <v-flex xs12 md6>
                  <h4 style="width: 150px">
                    Total: R$
                    {{
                      parseFloat(this.total)
                        .toFixed(2)
                        .toString()
                        .replace(".", ",")
                    }}
                  </h4>
                </v-flex>

                <v-flex xs12 md6>
                  <v-text-field
                    style="width: 100px"
                    label="Valor Pago"
                    v-model="valorPago"
                    class="purple-input"
                  />
                </v-flex>

                <v-flex xs20 md6>
                    <v-col class="d-flex" cols="12" sm="6">
                      <h5>Selecione uma forma de pagamento:</h5>
                      <v-select
                        @change="handleFormadePagamento"
                        :items="formaspagamento"
                        label="Formas de Pagamento"
                      ></v-select>
                    </v-col>
                    <div v-if="this.dadosPag.formaPagamento == 'Cartão de Crédito'">
                    <v-col class="d-flex" cols="12" sm="6" >
                      <h5>Selecione a bandeira:</h5>
                      <v-select
                        @change="handleBandeira"
                        :items="bandeiras"
                        label="Bandeiras"
                      ></v-select>
                    </v-col>
                    </div>
                    <div v-if="this.dadosPag.formaPagamento == 'Cartão de Crédito'">
                    <v-col class="d-flex" cols="12" sm="6">
                      <h5>Selecione a quantidade de parcelas:</h5>
                      <v-select
                        @change="handleParcelamento"
                        :items="parcelamento"
                        label="Parcelas"
                      ></v-select>
                    </v-col>
                    </div>
                </v-flex>
                <v-flex xs20 md9>
                  <v-text-field
                    style="width: 100px"
                    label="Frete"
                    v-model="frete"
                    class="purple-input"
                  />
                </v-flex>

                <v-flex inline>
                  <v-btn color="#e0d0bc" size="x-small" variant="text" v-if="avancadasConfig" v-on:click="avancadasConfig = false">
                    <v-icon>mdi-arrow-down-bold</v-icon>
                    Configurações avançadas
                  </v-btn>
                  <v-btn color="#e0d0bc" size="x-small" variant="text" v-else v-on:click="avancadasConfig = true">
                    <v-icon>mdi-arrow-right-thick</v-icon>
                    Configurações avançadas
                  </v-btn>
                </v-flex>

                <v-container v-if="avancadasConfig">

                <v-flex xs20 md9>
                  <h5>Local</h5>
                  <v-radio-group v-model="local" :mandatory="false">
                    <v-radio
                      label="Loja"
                      color="#9e8f7d"
                      value="loja"
                    ></v-radio>
                    <v-radio label="Fora da Loja" color="#9e8f7d" value="out"></v-radio>
                  </v-radio-group>
                </v-flex>
                
                <v-div>
                <v-container>
                  <h5>Data</h5>
                  <v-date-picker 
                  v-model="dataPagamento" 
                  class="mt-4" 
                  style="color:#00000"
                  color="black"
                  dark
                  header-color="primary"
                  locale="pt-br"></v-date-picker>
                </v-container>

                <v-flex xs12 md12>
                  <v-text-field
                    label="E-mail do Cliente"
                    v-model="emailcliente"
                    class="purple-input"
                  />
                  <v-text-field
                    label="CPF do Cliente"
                    v-model="cpfcliente"
                    class="purple-input"
                  />

                  <h4 v-if="cliente">
                    Cliente: {{ cliente.nome }} {{ cliente.sobrenome }} -
                    {{ cliente.cpf }} - {{ cliente.email }}
                  </h4>

                  <v-btn
                    class="mx-0 font-weight-light"
                    color="#9e8f7d"
                    @click="buscarCliente()"
                  >
                    Buscar
                  </v-btn>
                </v-flex>
                </v-div>
                </v-container>
                

                <v-flex xs12 text-xs-right>
                  <v-div v-if="!loadingCadastro">
                  <v-btn
                    class="mx-0 font-weight-light"
                    color="#9e8f7d"
                    @click="realizarVenda('bottom')"
                  >
                    Realizar Venda
                  </v-btn>

                  </v-div>
                  <v-div v-else>
                    <v-progress-circular
                      :size="50"
                      color="primary"
                      indeterminate
                    ></v-progress-circular>
                  </v-div>
                </v-flex>
              </v-layout>
            </v-container>
          </v-form>
        </material-card>
      </v-flex>

      <v-snackbar
        color="#000"
        :bottom="bottom"
        :top="top"
        :left="left"
        :right="right"
        v-model="snackbar"
        dark
      >
        <v-icon color="#fff" class="mr-3"> mdi-bell-plus</v-icon>
        <div>Venda realizada com sucesso!</div>
        <v-icon size="16" @click="snackbar = false"> mdi-close-circle </v-icon>
      </v-snackbar>

      <v-snackbar
        color="#BA0000"
        :bottom="bottom"
        :top="top"
        :left="left"
        :right="right"
        v-model="snackbarerror"
        dark
      >
        <v-icon color="#fff" class="mr-3"> mdi-bell-plus</v-icon>
        <div>
          Verifique os dados e tente novamente<br />Verifique a conexão com a
          internet!
        </div>
        <v-icon size="16" @click="snackbarerror = false">
          mdi-close-circle
        </v-icon>
      </v-snackbar>

      <v-snackbar
        color="#BA0000"
        :bottom="bottom"
        :top="top"
        :left="left"
        :right="right"
        v-model="snackbarerrorCodigo"
        dark
      >
        <v-icon color="#fff" class="mr-3"> mdi-bell-plus</v-icon>
        <div>Produto não encontrado!</div>
        <v-icon size="16" @click="snackbarerrorCodigo = false">
          mdi-close-circle
        </v-icon>
      </v-snackbar>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    dadosPag: {
      formaPagamento: "Forma de Pagamento",
      bandeira: "",
      parcelas: 1,
    },
    total: 0,
    dialog: false,
    valorPago: "",
    cpfcliente: "",
    emailcliente: "",
    avancadasConfig: false,
    loading: false,
    loadingCadastro: false,
    cliente: null,
    snackbar: false,
    snackbarerror: false,
    snackbarerrorCodigo: false,
    bottom: false,
    top: true,
    right: false,
    left: false,
    local: "loja",
    frete: 0,
    dataPagamento: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
    formaspagamento: [
      "Dinheiro",
      "Cartão de Crédito",
      "Cartão de Débito",
      "Out-Pagamento",
      "PIX",
      "Transferência",
    ],
    bandeiras: ["MasterCard", "Visa", "Elo", "American", "FortBrasil"],
    parcelamento: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
    headers: [
      {
        sortable: false,
        text: "Nome",
        value: "nome",
      },
      {
        sortable: false,
        text: "Preço",
        value: "preco",
      },
      {
        sortable: false,
        text: "Código de Barras",
        value: "codigodebarras",
      },
      {
        sortable: false,
        text: "Preço",
        value: "preco",
      },
    ],
    codigodoProdutonaBusca: "",
    list_produtos: [],
  }),
  methods: {
    handleFormadePagamento(e) {
      this.dadosPag.formaPagamento = e;
    },
    handleBandeira(e) {
      this.dadosPag.bandeira = e;
    },
    handleParcelamento(e) {
      this.dadosPag.parcelas = e;
    },
    getProdutoByCode() {
      // console.log(this.codigodoProdutonaBusca);
      this.loading = true;
      let codigo = this.codigodoProdutonaBusca;
      this.$http
        .get(`/produt/${codigo}`)
        .then((res) => {
          let data = res.data.produto[0];
          this.list_produtos.push({
            nome: data.nome,
            id: data._id,
            precoPago: data.preco.preco_venda,
            codigodebarras: data.codigoDeBarras,
            preco: data.preco.preco_venda,
          });
          if (res.data.produto === []) this.snackbarerrorCodigo = true;
          this.codigodoProdutonaBusca = "";
          this.calculateTotal();
          this.loading = false;
        })
        .catch((err) => {
          // console.log(err);

          this.snackbarerrorCodigo = true;
          this.loading = false;
        });
    },
    async realizarVenda(...args) {
      let vendedor = JSON.parse(localStorage.getItem("user"));
      let produtos = [];
      let e = null;

      for (e of this.list_produtos) {
        // console.log(e);
        produtos.push({
          id_prod: e.id,
          preco: parseFloat(e.preco.toString().replace(",", ".")).toFixed(2),
          valor: parseFloat(e.precoPago.toString().replace(",", ".")).toFixed(
            2
          ),
          qntSolicitada: 1,
        });
      }
      var d = new Date();
      var codigo =
        "VS" +
        d.getFullYear().toString().substring(2, 4) +
        d.getMonth().toString() +
        d.getDate().toString() +
        "/" +
        d.getSeconds().toString() +
        d.getMinutes().toString() +
        d.getHours().toString();
      let valor = parseFloat(this.valorPago.toString().replace(",", "."));
      let dados = {
        codigoVenda: codigo,
        precoPago: valor,
        vendedor: vendedor._id,
        quantidade: this.list_produtos.length,
        produtos: produtos,
        formaPagamento: this.dadosPag.formaPagamento,
        status: "Entregue",
        detalhesPagamento: {},
        frete: this.frete,
        local: this.local,
        data: this.dataPagamento,
        valorTotal: this.total,
      };

      if (this.cliente && this.cliente.user) {
        dados.comprador = this.cliente.user;
      }

      if (this.cpfcliente != "") {
        dados.cpf = this.cpfcliente;
      }

      if (this.dadosPag.bandeira) {
        dados.detalhesPagamento.bandeira = this.dadosPag.bandeira;
        dados.detalhesPagamento.parcelas = this.dadosPag.parcelas;
      }

      this.top = false;
      this.bottom = false;
      this.left = false;
      this.right = false;

      for (const loc of args) {
        this[loc] = true;
      }

      if (
        dados.produtos === [] ||
        dados.local === "" ||
        dados.precoPago === "" ||
        dados.vendedor === "" ||
        dados.formaPagamento === "Forma de Pagamento"
      ) {
        this.snackbarerror = true;
      } else {
        // console.log(dados);
        this.loadingCadastro = true
        await this.$http
          .post("/venda", dados)
          .then((res) => {
            this.loadingCadastro = false;
            this.snackbar = true;
            this.codigodoProdutonaBusca = "";
            this.list_produtos = [];
            this.cliente = null;
            this.cpfcliente = "";
            this.emailcliente = "";
            

            this.frete = 0
            this.dataPagamento = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
            this.valorPago = ""
          })
          .catch((err) => {
            // console.log(err);
            loadingCadastro = false
            this.snackbarerror = true
          })
      }
    },
    buscarCliente () {
      this.$http
        .get(`/clientes/busca/email/${encodeURIComponent(this.emailcliente)}`)
        .then((res) => {
          // console.log(res.data);
          this.cliente = res.data
          if (res.data.cpf) {
            this.cpfcliente = res.data.cpf
          }
        })
        .catch((err) => {
          if (err.error) alert(err.error)
        })
    },
    remover (item_) {
      // console.log(item_);

      let lista = this.list_produtos
      let nova = []
      let removido = false
      for (let i = 0; i < lista.length; i++) {
        if (lista[i].codigodebarras !== item_.codigodebarras) {
          nova.push(lista[i])
        } else {
          if (removido === true) {
            nova.push(lista[i])
          }
          removido = true
        }
      }
      // lista = lista.filter(
      //   (item, index) => item.codigodebarras !== item_.codigodebarras
      // )
      // console.log(lista);

      this.list_produtos = nova
      this.calculateTotal()
    },
    calculateTotal () {
      let soma = 0
      let somaT = 0
      let produto = null
      for (produto of this.list_produtos) {
        somaT += parseFloat(produto.preco.toString().replace(',', '.'))
        soma += parseFloat(produto.precoPago.toString().replace(',', '.'))
      }

      this.valorPago = soma.toFixed(2).toString().replace('.', ',')
      this.total = somaT
    }
  }
}
</script>
